
import { Component, Vue } from 'vue-property-decorator';

// import components
import ErrorBasic from '../components/error/ErrorBasic.vue';

@Component({
    components: {
        ErrorBasic,
    },
})
export default class ErrorPage extends Vue {};
